import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LoginPage from './components/login/LoginPage';
import NexusDashboard from './components/nexus/Dashboard';
import DataHiveDashboard from './components/dashboard/DataHiveDashboard';
import DataHiveSettings from './components/settings/Settings';
import DataHiveAPI from './components/api/API';

   function App() {
     return (
       <Router>
         <Routes>
           <Route path="/" element={<LoginPage />} />
           <Route path="/nexus/dashboard" element={<NexusDashboard />} />
           <Route path="/dashboard" element={<DataHiveDashboard />} />
           <Route path="/settings" element={<DataHiveSettings />} />
           <Route path="/publicAPI" element={<DataHiveAPI />} />
         </Routes>
       </Router>
     );
   }

   export default App;
