import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios'; // If not installed, install with npm install axios or yarn add axios.
import "../../style/master.css";
import { localConfig, qaConfig, prodConfig } from "../../config";
import { env } from "../../env";
import { useNavigate } from 'react-router-dom';

let config = {};
if (env === 'local') {
  config = localConfig;
}
else if (env === 'qa') {
  config = qaConfig;
}
else if (env === 'prod') {
  config = prodConfig;
}

const LoginPage = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [errorMsg, setErrorMsg] = useState(''); // State to store error message if login fails.
    const navigate = useNavigate();

    const checkTokenAndRedirect = useCallback(async () => {
        const expiryDateStr = localStorage.getItem("dataHive_expiry");
        if (expiryDateStr) {
          const token = localStorage.getItem("dataHive_userToken");
          if (token) {
            const expiryDate = new Date(expiryDateStr);
            if (expiryDate > new Date()) {
              try {
                const response = await axios.get(
                  `${config.apiBaseUrl}${config.endpoints.checkToken}`,
                  { headers: { Authorization: `Bearer ${token}` } }
                );
    
                if (response?.status === 200) {
                  navigate("/dashboard");
                } else {
                  console.error("Token verification failed.");
                }
              } catch (error) {
                if (error?.response?.status === 401) {
                  console.warn("Unauthorized token detected.");
                } else {
                  console.error("Unexpected error:", error);
                }
              }
            } else {
              console.warn("Token expired.");
            }
          }
        } else {
          console.warn("Token not found in local storage.");
        }
      }, [navigate]); // ✅ Added `navigate` as a dependency
    
      useEffect(() => {
        checkTokenAndRedirect();
      }, [checkTokenAndRedirect]); // ✅ Now it has a stable reference

    // Handle form submission. The event object is passed here and its default action (page refresh) is prevented using preventDefault() method.
    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            let response = await axios({
                url: `${config.apiBaseUrl}${config.endpoints.authenticate}`, // Replace with your own login API endpoint.
                method: 'post',
                data: { username, password },
                headers: { 'Content-Type': 'application/json' },
            });
            console.log(response.data);
            if (response && response.data) { // If successful server returns JWT token or any unique id in response body.
                localStorage.setItem('dataHive_userToken', response.data.token); // Save the user token to local storage for use with future API requests (if required). You can also save it into contextAPI if you're using one like Redux, Mobx etc..
                localStorage.setItem('dataHive_clientIds', response.data.clientIds);
                localStorage.setItem('dataHive_clientId', response.data.clientIds[0]);
                localStorage.setItem('dataHive_clientNames', response.data.clientNames);
                localStorage.setItem('dataHive_clientName', response.data.clientNames[0]);
                localStorage.setItem('dataHive_expiry', response.data.expiry);
                localStorage.setItem('dataHive_authenticated', true);
                localStorage.setItem('dataHive_username', username);
                localStorage.setItem('dataHive_userId', response.data.userId);
                const userKey = btoa(`${username}:${password}`); // Base64 encode the username and password
                localStorage.setItem('datahive_userkey', userKey); // Store the encoded value in localStorage
                navigate('/dashboard'); // Use react-router library and its useHistory hook for redirection after successful login. If not installed install as npm i react-router-dom or yarn add react-router-dom
            } else {
                setErrorMsg('Invalid username or password.'); // Set the error message state if an error occurs during login (incorrect credentials, network errors etc.)
            }
        } catch (error) {
            setErrorMsg('An unexpected error occurred. Please try again later.'); // Handle any unforeseen exceptions here and display a more generic error to user for security reasons.
        }
    };

    return (
        <div className="login-page">{/* Apply the login-page class here */}
            <form onSubmit={handleSubmit} className="login-form"> {/* Apply the login-form class to form element for styling and positioning it in center of screen. */}
                <img src="https://sanexuspreprod.blob.core.windows.net/datahive-images/datahive.png" alt="Logo" className='logo' />
                <h1 className="login-heading">DataHive {config.labels.envLabel} Login</h1>
                <h2 className="login-subheading">Please input your login details to login to DataHive Portal</h2>
                <input type="text" placeholder='Username' onChange={e => setUsername(e.target.value)} className='rounded-input' />
                <input type="password" placeholder='Password' onChange={e => setPassword(e.target.value)} className='rounded-input' /> {/* you should use input field of type password for security reasons */}
                <br></br><br></br><br></br>
                <button onClick={handleSubmit} className="submit-button">Sign In</button> {/* replace 'handleSubmit' with your actual function that handles form submission*/}
                {errorMsg && <p className="error-msg">{errorMsg}</p>}{/* Error message paragraph element*/}
            </form>
        </div>
    );
};
export default LoginPage; // Export the login page component as a module. It can be imported in other components or files for rendering inside another component, route etc..

