import { useNavigate } from "react-router-dom";
import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import "../../style/master.css";
import { localConfig, qaConfig, prodConfig } from "../../config";
import { env } from "../../env";
import CircleLoader from "react-spinners/CircleLoader";
import { Toaster } from "react-hot-toast";
import { Link } from 'react-router-dom';

const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
};

let config = {};
if (env === 'local') {
  config = localConfig;
}
else if (env === 'qa') {
  config = qaConfig;
}
else if (env === 'prod') {
  config = prodConfig;
}

function DataHiveDashboard({ history }) {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const clientNames = (localStorage.getItem("dataHive_clientNames") || "").split(",");
  const clientIds = (localStorage.getItem("dataHive_clientIds") || "").split(",");
  const [selectedClientId, setSelectedClientId] = useState(localStorage.getItem("dataHive_clientId") || "");

  const handleClientChange = (event) => {
    const newClientId = event.target.value;
    const newClientName = clientNames[clientIds.findIndex((id) => id === newClientId)];
    setSelectedClientId(newClientId);
    localStorage.setItem("dataHive_clientId", newClientId);
    localStorage.setItem("dataHive_clientName", newClientName);
    navigate("/");
  };

  function handleLogout() {
    // custom event handler function called whenever logout button is clicked by end users. This removes token from local storage or cookies/sessionStorage, and redirects back to login page using history object provided by React Router library internally behind the scenes while developing/maintaining web applications over time after initial deployment into production environment where end users interact with it daily without any downtime issues etc..
    localStorage.clear(); // remove token from local storage or cookies/sessionStorage depending upon implementation details related to security and performance optimization of your specific use case scenario inside real-world business environment where this application may be used daily by thousands or millions of end users simultaneously without any downtime issues etc..
    navigate("/"); // redirect back to login page using 'history' object provided by React Router library internally behind the scenes while developing/maintaining web applications over time after initial deployment into production environment where end users interact with it daily without any downtime issues etc..
  }

  const checkToken = useCallback(async () => {
    const auth = localStorage.getItem("dataHive_authenticated");
    if (auth === "true") {
      const expiryDateStr = localStorage.getItem("dataHive_expiry");
      if (expiryDateStr) {
        const token = localStorage.getItem("dataHive_userToken");
        if (token) {
          const expiryDate = new Date(expiryDateStr);
          if (expiryDate > new Date()) {
            // Token is still valid
            return;
          }
        }
      }
    }
    localStorage.clear();
    navigate("/");
    console.warn("Token expired or invalid.");
  }, [navigate]); // Only depends on `navigate`

  const fetchProducts = useCallback(async () => {
    try {
      const userId = localStorage.getItem("dataHive_clientId");
      const response = await axios.get(
        `${config.apiBaseUrl}${config.endpoints.getUserData}?userId=${userId}`
      );
      setProducts(response.data.userProducts.$values);
    } catch (error) {
      console.log("Error fetching products:", error);
    }
  }, []); // No dependencies, since it only fetches from local storage

  useEffect(() => {
    setLoading(true);
    checkToken();
    fetchProducts();
    setLoading(false);
  }, [checkToken, fetchProducts]); // Dependencies are now stable

  return (
    <div>
      <header className="header">
        {/* render toggle switch component for auto refresh feature */}
        <div className="header-left">
          <img src="https://sanexuspreprod.blob.core.windows.net/datahive-images/datahive.png" alt="Logo" className="header-logo" />
        </div>
        <div className="header-center">
          <h3 className="section-header">DataHive Dashboard</h3>
        </div>
        <div className="header-right">
          {/* render client name label if available */}
          <div className="logged-in-as-div">
            <label className="logged-in-as">Environment:</label>
            {config.labels.envLabel && (
              <label className="logged-in-as">
                {config.labels.envLabel}
              </label>
            )}
          </div>
          <div className="logged-in-as-div">
            <label className="logged-in-as">Logged in as:</label>
            {localStorage.getItem("dataHive_username") && (
              <label className="logged-in-as" htmlFor="client-name">
                {localStorage.getItem("dataHive_username")}
              </label>
            )}
          </div>
          <div className="client-selector-div">
          {/* add client selector dropdown */}
          <label className="client-selector-label">Company:</label>
          <select value={selectedClientId} onChange={handleClientChange} className="client-selector">
            {clientNames.map((name, index) => (
              <option key={clientIds[index]} value={clientIds[index]}>
                {name}
              </option>
            ))}
          </select>
          </div>
          <button onClick={handleLogout} className="submit-button">
            Logout
          </button>
        </div>
      </header>
      <div className="dashboard">
        <div>
          <Toaster position="top-center" reverseOrder={false} />
        </div>
        <div className="main-content">
          <div className="product-grid">
            {products.map((product, index) => {
              const isDisabled = !product.enabled || !product.access;
              const overlayText = !product.enabled ? "Coming Soon!" : "Disabled for client";

              return (
                <div key={index} className="product-button-wrapper">
                  <Link
                    to={product.route}
                    className={`product-button ${isDisabled ? "disabled" : ""}`}
                    style={{ pointerEvents: isDisabled ? "none" : "auto" }}
                  >
                    <img src={product.image} alt={product.name} />
                    <span>{product.name}</span>
                  </Link>
                  {isDisabled && <div className="overlay">{overlayText}</div>}
                </div>
              );
            })}
          </div>

          {loading && (
            <div
              className="loading-spinner-container"
              style={{
                position: "fixed",
                top: 0,
                left: 0,
                width: "100vw",
                height: "100vh",
                backgroundColor: "rgba(0, 0, 0, 0.4)",
                backdropFilter: "blur(3px)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                zIndex: 999,
              }}
            >
              <CircleLoader
                color="#f06d37"
                loading={loading}
                cssOverride={override}
                size={150}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            </div>
          )}
        </div>
        <div className="refresh">
          <div className="auto-refresh">
            <label htmlFor="auto-refresh">Copyright 2024 Honeysoft</label>
          </div>
        </div>
      </div>
    </div>
  );
}
export default DataHiveDashboard;
