export const localConfig = {
    apiBaseUrl: 'http://localhost:5051',
    flowBaseUrl: 'https://flow.honeysoft.app',
    portBaseUrl: 'https://port.honeysoft.app/api',
    endpoints: {
        authenticate: '/UserAuth/authenticate',
        checkToken: '/UserAuth/checkToken',
        getDashboardData: '/Client/getDashboardData',
        getClientName: '/Client/getName',
        getUserData: '/User/getUserData',
        createIntegrationRun: '/Integration/createIntegrationRun',
        toggleIntegrationStatus: '/Integration/toggleIntegrationStatus',
        getWorkflowData: '/api/v1/workflows',
        activateWorkflow: '/api/v1/workflows/~id~/activate',
        deactivateWorkflow: '/api/v1/workflows/~id~/deactivate',
        getExecutionData: '/api/v1/executions',
        workflowWebhook: '/webhook/~id~',
        getExecutionDBData: '/User/getExecutionData',
        dckCmdInit: '/endpoints/2/docker/containers/bes_nexus/exec',
        dckCmdExec: '/endpoints/2/docker/exec/~id~/start'
    },
    credentials: {
        flowApiKey: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIzOGI3MjBjMi04NzA2LTQxMjMtYTNjMi1iMjE4ZWUyY2UxMjMiLCJpc3MiOiJuOG4iLCJhdWQiOiJwdWJsaWMtYXBpIiwiaWF0IjoxNzM1MDMxNjcxfQ.zEyCkzNWBFhhEp1VT6_1HWRIYq08kht-Os3JHMZpEI0",
        portApiKey: "ptr_yuYHsaKOASioaulswHBHCDEWVQ0esVoPY0vazs0zYCE="
    },
    flow: {
        includedTags: "qa"
    },
    labels: {
        envLabel: "LOCAL"
    },
};

export const qaConfig = {
    apiBaseUrl: 'https://qa.bff.honeysoft.app',
    //flowBaseUrl: 'http://10.0.2.7:5678',
    flowBaseUrl: 'https://flow.honeysoft.app',
    portBaseUrl: 'https://port.honeysoft.app/api',
    endpoints: {
        authenticate: '/UserAuth/authenticate',
        checkToken: '/UserAuth/checkToken',
        getDashboardData: '/Client/getDashboardData',
        getClientName: '/Client/getName',
        getUserData: '/User/getUserData',
        createIntegrationRun: '/Integration/createIntegrationRun',
        toggleIntegrationStatus: '/Integration/toggleIntegrationStatus',
        getWorkflowData: '/api/v1/workflows',
        activateWorkflow: '/api/v1/workflows/~id~/activate',
        deactivateWorkflow: '/api/v1/workflows/~id~/deactivate',
        getExecutionData: '/api/v1/executions',
        workflowWebhook: '/webhook/~id~',
        getExecutionDBData: '/User/getExecutionData',
        dckCmdInit: '/endpoints/2/docker/containers/bes_nexus/exec',
        dckCmdExec: '/endpoints/2/docker/exec/~id~/start'
    },
    credentials: {
        flowApiKey: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIzOGI3MjBjMi04NzA2LTQxMjMtYTNjMi1iMjE4ZWUyY2UxMjMiLCJpc3MiOiJuOG4iLCJhdWQiOiJwdWJsaWMtYXBpIiwiaWF0IjoxNzM1MDMxNjcxfQ.zEyCkzNWBFhhEp1VT6_1HWRIYq08kht-Os3JHMZpEI0",
        portApiKey: "ptr_yuYHsaKOASioaulswHBHCDEWVQ0esVoPY0vazs0zYCE="
    },
    flow: {
        includedTags: "qa"
    },
    labels: {
        envLabel: "QA"
    },
};

export const prodConfig = {
    apiBaseUrl: 'https://bff.honeysoft.app',
    flowBaseUrl: 'https://flow.honeysoft.app',
    portBaseUrl: 'https://port.honeysoft.app/api',
    endpoints: {
        authenticate: '/UserAuth/authenticate',
        checkToken: '/UserAuth/checkToken',
        getDashboardData: '/Client/getDashboardData',
        getClientName: '/Client/getName',
        getUserData: '/User/getUserData',
        createIntegrationRun: '/Integration/createIntegrationRun',
        toggleIntegrationStatus: '/Integration/toggleIntegrationStatus',
        getWorkflowData: '/api/v1/workflows',
        activateWorkflow: '/api/v1/workflows/~id~/activate',
        deactivateWorkflow: '/api/v1/workflows/~id~/deactivate',
        getExecutionData: '/api/v1/executions',
        workflowWebhook: '/webhook/~id~',
        getExecutionDBData: '/User/getExecutionData',
        dckCmdInit: '/endpoints/2/docker/containers/bes_nexus/exec',
        dckCmdExec: '/endpoints/2/docker/exec/~id~/start'
    },
    credentials: {
        flowApiKey: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIzOGI3MjBjMi04NzA2LTQxMjMtYTNjMi1iMjE4ZWUyY2UxMjMiLCJpc3MiOiJuOG4iLCJhdWQiOiJwdWJsaWMtYXBpIiwiaWF0IjoxNzM1MDMxNjcxfQ.zEyCkzNWBFhhEp1VT6_1HWRIYq08kht-Os3JHMZpEI0",
        portApiKey: "ptr_yuYHsaKOASioaulswHBHCDEWVQ0esVoPY0vazs0zYCE="
    },
    flow: {
        includedTags: "prod"
    },
    labels: {
        envLabel: "PROD"
    },
};