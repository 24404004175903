import { useNavigate, useLocation } from "react-router-dom";
import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import "../../style/master.css";
import { localConfig, qaConfig, prodConfig } from "../../config";
import { env } from "../../env";
import CircleLoader from "react-spinners/CircleLoader";
import { Link } from "react-router-dom";
import SwaggerUI from "swagger-ui-react";
import "swagger-ui-react/swagger-ui.css";


const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
};

let config = {};
if (env === 'local') {
  config = localConfig;
}
else if (env === 'qa') {
  config = qaConfig;
}
else if (env === 'prod') {
  config = prodConfig;
}

function DataHiveAPI({ history }) {
  const [swaggerUrl, setSwaggerUrl] = useState("https://qa.api.honeysoft.app/swagger/v1/swagger.json");
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const clientNames = (localStorage.getItem("dataHive_clientNames") || "").split(",");
  const clientIds = (localStorage.getItem("dataHive_clientIds") || "").split(",");
  const [selectedClientId, setSelectedClientId] = useState(localStorage.getItem("dataHive_clientId") || "");
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const location = useLocation();

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const handleClientChange = (event) => {
    const newClientId = event.target.value;
    const newClientName = clientNames[clientIds.findIndex((id) => id === newClientId)];
    setSelectedClientId(newClientId);
    localStorage.setItem("dataHive_clientId", newClientId);
    localStorage.setItem("dataHive_clientName", newClientName);
    navigate("/publicAPI");
  };

  const checkToken = useCallback(() => {
    const auth = localStorage.getItem("dataHive_authenticated");
    if (!auth || auth !== "true") {
      localStorage.clear();
      navigate("/");
      return;
    }

    const expiryDateStr = localStorage.getItem("dataHive_expiry");
    if (!expiryDateStr) {
      localStorage.clear();
      navigate("/");
      console.warn("Token not found in local storage.");
      return;
    }

    const token = localStorage.getItem("dataHive_userToken");
    if (token) {
      const expiryDate = new Date(expiryDateStr);
      if (expiryDate <= new Date()) {
        localStorage.clear();
        navigate("/");
        console.warn("Token expired.");
      }
    }
  }, [navigate]);

  const fetchProducts = useCallback(async () => {
    try {
      const userId = localStorage.getItem("dataHive_clientId");
      const response = await axios.get(
        `${config.apiBaseUrl}${config.endpoints.getUserData}?userId=${userId}`
      );
      setProducts(response.data.userProducts.$values);
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  }, []);

  useEffect(() => {
    setLoading(true);
    const queryParams = new URLSearchParams(location.search);
    const route = queryParams.get("route");
    console.log("Route from query params:", route);
    if (route) {
      setSwaggerUrl(route);
    }
    checkToken();
    fetchProducts();
    setLoading(false);
  }, [checkToken, fetchProducts, location.search]); // ✅ Added missing dependencies

  function handleLogout() {
    // custom event handler function called whenever logout button is clicked by end users. This removes token from local storage or cookies/sessionStorage, and redirects back to login page using history object provided by React Router library internally behind the scenes while developing/maintaining web applications over time after initial deployment into production environment where end users interact with it daily without any downtime issues etc..
    localStorage.clear(); // remove token from local storage or cookies/sessionStorage depending upon implementation details related to security and performance optimization of your specific use case scenario inside real-world business environment where this application may be used daily by thousands or millions of end users simultaneously without any downtime issues etc..
    navigate("/"); // redirect back to login page using 'history' object provided by React Router library internally behind the scenes while developing/maintaining web applications over time after initial deployment into production environment where end users interact with it daily without any downtime issues etc..
  }

  return (
    <div>
      <header className="header">
        {/* render toggle switch component for auto refresh feature */}
        <div className="header-left">
          <img src="https://sanexuspreprod.blob.core.windows.net/datahive-images/datahive.png" alt="Logo" className="header-logo" onClick={toggleSidebar}/>
        </div>
        <div className="header-center">
          <h3 className="section-header">DataHive Public API</h3>
        </div>
        <div className="header-right">
          {/* render client name label if available */}
          <div className="logged-in-as-div">
            <label className="logged-in-as">Environment:</label>
            {config.labels.envLabel && (
              <label className="logged-in-as">
                {config.labels.envLabel}
              </label>
            )}
          </div>
          <div className="logged-in-as-div">
            <label className="logged-in-as">Logged in as:</label>
            {localStorage.getItem("dataHive_username") && (
              <label className="logged-in-as" htmlFor="client-name">
                {localStorage.getItem("dataHive_username")}
              </label>
            )}
          </div>
          <div className="client-selector-div">
          {/* add client selector dropdown */}
          <label className="client-selector-label">Company:</label>
          <select value={selectedClientId} onChange={handleClientChange} className="client-selector">
            {clientNames.map((name, index) => (
              <option key={clientIds[index]} value={clientIds[index]}>
                {name}
              </option>
            ))}
          </select>
          </div>
          <button onClick={handleLogout} className="submit-button">
            Logout
          </button>
        </div>
      </header>
      <div className="dashboard">
        <div className="main-content">
          <div className={`sidebar ${sidebarOpen ? "open" : ""}`}>
            <button className="close-btn" onClick={toggleSidebar}>
              ×
            </button>
            <nav>
              <ul>
              <li className="inactive-link">
                  <Link to="/dashboard" onClick={toggleSidebar}>
                  <img src="https://sanexuspreprod.blob.core.windows.net/datahive-images/datahive.png" className="sidebar-logo" alt=""/>
                  <span className="sidebar-text">Home</span>
                  </Link>
                </li>
                {products.map((product, index) => (
                  <li
                    key={index}
                    className={location.pathname === product.route ? "active-link" : "inactive-link"}
                    style={{display: !product.enabled || !product.access ? "none" : "auto " }}>
                    <Link 
                      to={product.route} 
                      onClick={toggleSidebar}
                      className={`sidebar-link ${location.pathname === product.route ? "disabled" : ""}`}
                      style={{ pointerEvents: location.pathname === product.route || !product.enabled || !product.access ? "none" : "auto" }}
                    >
                      <img src={product.image} className="sidebar-logo" alt=""/>
                      <span className="sidebar-text">{product.name}</span>
                    </Link>
                  </li>
                ))}
              </ul>
            </nav>
          </div>
          <button className="toggle-stats" onClick={toggleSidebar}>
            {sidebarOpen ? "←" : "→"}
          </button>
          
          <div className="swagger-container">
            <SwaggerUI url={swaggerUrl} />
          </div>


          {loading && (
            <div
              className="loading-spinner-container"
              style={{
                position: "fixed",
                top: 0,
                left: 0,
                width: "100vw",
                height: "100vh",
                backgroundColor: "rgba(0, 0, 0, 0.4)",
                backdropFilter: "blur(3px)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                zIndex: 999,
              }}
            >
              <CircleLoader
                color="#f06d37"
                loading={loading}
                cssOverride={override}
                size={150}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            </div>
          )}
        </div>
        <div className="refresh">
          <div className="auto-refresh">
            <label htmlFor="auto-refresh">Copyright 2024 Honeysoft</label>
          </div>
        </div>
      </div>
    </div>
  );
}
export default DataHiveAPI;
