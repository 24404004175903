import { useNavigate, useLocation } from "react-router-dom";
import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import "../../style/master.css";
import { localConfig, qaConfig, prodConfig } from "../../config";
import { env } from "../../env";
import CircleLoader from "react-spinners/CircleLoader";
import toast, { Toaster } from "react-hot-toast";
import Select from "react-select";
import { Link } from "react-router-dom";
import {
  FaDownload,
  FaCaretDown,
  FaCaretRight,
  FaFileExcel,
} from "react-icons/fa";
import * as XLSX from "xlsx";
//import { FaEye, FaCopy } from "react-icons/fa";

const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
};

const topOptions = [
  { value: "1", label: "1" },
  { value: "3", label: "3" },
  { value: "5", label: "5" },
  { value: "10", label: "10" },
  { value: "15", label: "20" },
  { value: "50", label: "50" },
];

let config = {};
if (env === "local") {
  config = localConfig;
} else if (env === "qa") {
  config = qaConfig;
} else if (env === "prod") {
  config = prodConfig;
}

function NexusDashboard({ history }) {
  const [data, setData] = useState(null);
  const [executionData, setExecutionData] = useState([]);
  const [errorMessageData, setErrorMessageData] = useState([]);
  const [products, setProducts] = useState([]);
  const [topData, setTopData] = useState("1");
  const [lastRefresh, setLastRefresh] = useState("");
  const [loading, setLoading] = useState(true);
  const [autoRefresh, setAutoRefresh] = useState(false);
  const navigate = useNavigate();
  const [isExpanded, setIsExpanded] = useState(true);
  const [isExecExpanded, setExecIsExpanded] = useState(true);
  const [isTriggersExpanded, setTriggersExpanded] = useState({});
  const [isExecutionsExpanded, setExecutionsExpanded] = useState({});
  //const [modalIsOpen, setModalIsOpen] = useState(false);
  //const [modalNodes, setModalNodes] = useState([]);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const location = useLocation();
  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };
  // const openModal = (nodes) => {
  //   setModalNodes(nodes);
  //   setModalIsOpen(true);
  // };
  // const closeModal = () => {
  //   setModalIsOpen(false);
  // };
  const clientNames = (
    localStorage.getItem("dataHive_clientNames") || ""
  ).split(",");
  const clientIds = (localStorage.getItem("dataHive_clientIds") || "").split(
    ","
  );
  const [selectedClientId, setSelectedClientId] = useState(
    localStorage.getItem("dataHive_clientId") || ""
  );
  const [selectedClientName, setSelectedClientName] = useState(
    localStorage.getItem("dataHive_clientName") || ""
  );

  const handleClientChange = (event) => {
    const newClientId = event.target.value;
    const newClientName =
      clientNames[clientIds.findIndex((id) => id === newClientId)];
    setSelectedClientId(newClientId);
    setSelectedClientName(newClientName);
    localStorage.setItem("dataHive_clientId", newClientId);
    localStorage.setItem("dataHive_clientName", newClientName);
    navigate("/nexus/dashboard");
  };

  const handleDataDownload = (executionData, executionId) => {
    const jsonString = JSON.stringify(executionData, null, 2); // Format JSON nicely
    const blob = new Blob([jsonString], { type: "application/json" });
    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = `executionData_${executionId}.json`; // File name
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  const handleErrorDownload = (execeptionData, executionId) => {
    const jsonString = JSON.stringify(execeptionData, null, 2); // Format JSON nicely
    const blob = new Blob([jsonString], { type: "application/json" });
    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = `execeptionData_${executionId}.json`; // File name
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  const flattenObject = (obj, prefix = "") => {
    const result = {};

    Object.keys(obj).forEach((key) => {
      const value = obj[key];
      const newKey = prefix ? `${prefix}.${key}` : key;

      if (
        typeof value === "object" &&
        !Array.isArray(value) &&
        value !== null
      ) {
        Object.assign(result, flattenObject(value, newKey));
      } else if (Array.isArray(value)) {
        // Flatten arrays directly into the result
        value.forEach((item, index) => {
          const flattenedItem = flattenObject(item, `${newKey}.${index}`);
          Object.assign(result, flattenedItem);
        });
      } else {
        result[newKey] = value;
      }
    });

    return result;
  };

  const handleErrorDownloadExcel = (exceptionErrors, executionId) => {
    if (!Array.isArray(exceptionErrors)) {
      console.error("Error: Invalid data format. Cannot generate Excel file.");
      return;
    }

    const groupedErrors = {};

    exceptionErrors.forEach((error) => {
      try {
        // Parse the JSON strings
        const lineData = JSON.parse(error.lineData || "{}");
        const errorData = JSON.parse(error.errorData || "{}");

        // Only process if Success is explicitly false
        if (errorData.json?.Success === false) {
          const errorMessages =
            errorData.json?.Errors?.length > 0
              ? errorData.json.Errors
              : ["No error returned by Sage."];

          const schema = errorData.json?.Schema || "";
          const sageHeaderId = errorData.json?.SageHeaderID || "";

          // Process lineData, whether it's an array or an object
          const processEntry = (item) => {
            const flattenedItem = flattenObject(item);
            errorMessages.forEach((errorMessage) => {
              const sageHeaderKey = sageHeaderId || "No Header ID Found.";
              const entry = {
                SageHeaderID: sageHeaderKey,
                ...flattenedItem,
                ErrorMessage: errorMessage,
              };
              const schemaKey = schema || "Uncategorized";
              if (!groupedErrors[schemaKey]) {
                groupedErrors[schemaKey] = [];
              }
              groupedErrors[schemaKey].push(entry);
            });
          };

          if (Array.isArray(lineData)) {
            lineData.forEach(processEntry);
          } else if (typeof lineData === "object") {
            processEntry(lineData);
          }
        }
      } catch (e) {
        console.error("Error parsing JSON for entry:", e);
      }
    });

    // Create a new workbook and add each schema as a separate sheet
    const workbook = XLSX.utils.book_new();
    Object.keys(groupedErrors).forEach((schema) => {
      const worksheet = XLSX.utils.json_to_sheet(groupedErrors[schema]);
      XLSX.utils.book_append_sheet(workbook, worksheet, schema);
    });

    // Download the workbook
    XLSX.writeFile(workbook, `ExceptionData_${executionId}.xlsx`);
  };

  const checkToken = useCallback(async () => {
    const auth = localStorage.getItem("dataHive_authenticated");
    if (auth) {
      if (auth === "true") {
        const expiryDateStr = localStorage.getItem("dataHive_expiry");
        if (expiryDateStr) {
          const token = localStorage.getItem("dataHive_userToken");
          if (token) {
            const expiryDate = new Date(expiryDateStr);
            if (expiryDate > new Date()) {
              return; // Token is valid, no action needed
            } else {
              localStorage.clear();
              navigate("/");
              console.warn("Token expired.");
            }
          }
        } else {
          localStorage.clear();
          navigate("/");
          console.warn("Token not found in local storage.");
        }
      } else {
        localStorage.clear();
        navigate("/");
      }
    } else {
      localStorage.clear();
      navigate("/");
    }
  }, [navigate]); // Ensure navigate is stable by including it as a dependency.

  const fetchData = useCallback(async () => {
    let currentDate = new Date();
    setExecutionData([]);
    try {
      setLoading(true);
      await new Promise((resolve) => setTimeout(resolve, 500));

      const response = await axios.get(
        `${config.flowBaseUrl}${
          config.endpoints.getWorkflowData
        }?tags=${selectedClientName.toLowerCase()}, ${
          config.flow.includedTags
        }`,
        {
          headers: {
            "X-N8N-API-KEY": config.credentials.flowApiKey,
          },
        }
      );

      setData(response.data);
      setLastRefresh(currentDate.toTimeString());
      setLoading(false);
    } catch (error) {
      console.log("Error fetching workflow data:", error);
    }
  }, [selectedClientName]);

  useEffect(() => {
    if (!data || !data.data || data.data.length === 0) return;

    const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

    const fetchExecutionData = async () => {
      setLoading(true);
      setExecutionData([]); // Ensure executionData is cleared before fetching
      try {
        for (const item of data.data) {
          // Add a delay before each request
          await delay(100);

          const response = await axios.get(
            `${config.flowBaseUrl}${config.endpoints.getExecutionData}?includeData=false&limit=${topData}&workflowId=${item.id}`,
            {
              headers: {
                "X-N8N-API-KEY": config.credentials.flowApiKey,
              },
            }
          );

          const enrichedExecutions = await Promise.all(
            response.data.data.map(async (execution) => {
              //Get and set error
              if (!execution.finished) {
                const errResponse = await axios.get(
                  `${config.flowBaseUrl}${config.endpoints.getExecutionData}/${execution.id}?includeData=true`,
                  {
                    headers: {
                      "X-N8N-API-KEY": config.credentials.flowApiKey,
                      withCredentials: false,
                    },
                  }
                );

                // Build the new error message object
                const newErrorMessage = {
                  executionId: execution.id,
                  message:
                    "Last node executed: " +
                    errResponse?.data?.data?.resultData?.lastNodeExecuted +
                    " with error: " +
                    errResponse?.data?.data?.resultData?.error?.message,
                };

                // Update the state with the new error message only if it's unique
                setErrorMessageData((prevErrorMessages) => {
                  // Check if the new error message already exists in the array
                  const isDuplicate = prevErrorMessages.some(
                    (error) =>
                      error.executionId === newErrorMessage.executionId &&
                      error.message === newErrorMessage.message
                  );

                  // If it's a duplicate, return the previous state unchanged
                  if (isDuplicate) {
                    console.log(
                      "Duplicate found, not adding:",
                      newErrorMessage
                    );
                    return prevErrorMessages;
                  }

                  // Otherwise, add the new error message to the array
                  console.log("Adding new error message:", newErrorMessage);
                  return [...prevErrorMessages, newErrorMessage];
                });
              }

              const executionDataResponse = await axios.get(
                `${config.apiBaseUrl}${config.endpoints.getExecutionDBData}?executionId=${execution.id}`
              );

              // Merge executionErrors and executionData into the execution object
              return {
                ...execution,
                executionErrors:
                  executionDataResponse.data.executionErrors?.$values || [],
                executionData:
                  executionDataResponse.data.executionData?.$values || [],
              };
            })
          );

          setExecutionData((prevExecutionData) => {
            const newExecutionData = [
              ...prevExecutionData,
              ...enrichedExecutions,
            ];
            const uniqueExecutionData = Array.from(
              new Set(newExecutionData.map((a) => a.id))
            ).map((id) => newExecutionData.find((a) => a.id === id));
            return uniqueExecutionData;
          });
        }

        setLoading(false);
      } catch (error) {
        console.log("Error fetching execution data:", error);
      }
    };

    fetchExecutionData();
  }, [data, topData]);

  useEffect(() => {
    checkToken();
    let intervalId;

    fetchData();
    fetchProducts();

    if (autoRefresh) {
      intervalId = setInterval(fetchData, 5 * 1000);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [autoRefresh, topData, selectedClientName, checkToken, fetchData]);

  async function fetchProducts() {
    try {
      const userId = localStorage.getItem("dataHive_clientId");
      const response = await axios.get(
        `${config.apiBaseUrl}${config.endpoints.getUserData}?userId=${userId}`
      );
      setProducts(response.data.userProducts.$values);
    } catch (error) {
      console.log("Error fetching products:", error);
    }
  }

  function handleLogout() {
    localStorage.clear();
    navigate("/");
  }

  async function handleIntegrationRun(iuid) {
    const initUrl = config.portBaseUrl + config.endpoints.dckCmdInit;
    const execUrl = config.portBaseUrl + config.endpoints.dckCmdExec;

    const portExec = axios
      .post(
        initUrl,
        {
          AttachStdout: true,
          AttachStderr: true,
          Tty: false,
          User: "node",
          Cmd: ["n8n", "execute", "--id", iuid],
        },
        {
          headers: { "x-api-key": config.credentials.portApiKey },
          withCredentials: false,
          timeout: 60000,
        }
      )
      .then((response) => {
        return axios.post(
          execUrl.replace("~id~", response.data.Id),
          {},
          {
            headers: { "x-api-key": config.credentials.portApiKey },
            withCredentials: false,
            timeout: 60000,
          }
        );
      });

    toast
      .promise(
        portExec,
        {
          loading: "Workflow execution in progress...",
          success: <b>Workflow execution completed!</b>,
          error: <b>Workflow execution failed!</b>,
        },
        {
          style: {
            minWidth: "350px",
          },
        }
      )
      .then(() => {
        fetchData();
      });
  }

  async function handleIntegrationDisable(iuid, currentStatus) {
    const url = currentStatus
      ? config.flowBaseUrl +
        config.endpoints.deactivateWorkflow.replace("~id~", iuid)
      : config.flowBaseUrl +
        config.endpoints.activateWorkflow.replace("~id~", iuid);

    const wait = axios
      .post(
        url,
        {},
        {
          headers: {
            "X-N8N-API-KEY": config.credentials.flowApiKey,
          },
        }
      )
      .then(
        (response) =>
          new Promise((resolve) => setTimeout(() => resolve(response), 500))
      );

    const text = !currentStatus ? "Enabling" : "Disabling";
    toast
      .promise(
        wait,
        {
          loading: text + " workflow...",
          success: <b>{text} workflow success!</b>,
          error: <b>{text} workflow failed.</b>,
        },
        {
          style: {
            minWidth: "350px",
          },
        }
      )
      .then(() => {
        fetchData();
      });
  }

  const toPascalCase = (str) => {
    if (!str) return "";
    return str
      .toLowerCase()
      .split(/[\s_-]+/) // Handles spaces, underscores, and hyphens
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join("");
  };

  return (
    <div>
      <header className="header">
        {/* render toggle switch component for auto refresh feature */}
        <div className="header-left">
          <img
            src="https://sanexuspreprod.blob.core.windows.net/datahive-images/nexus.png"
            alt="Logo"
            className="header-logo"
            onClick={toggleSidebar}
          />
        </div>
        <div className="header-center">
          <h3 className="section-header">Nexus Dashboard</h3>
        </div>
        <div className="header-right">
          {/* render client name label if available */}
          <div className="logged-in-as-div">
            <label className="logged-in-as">Environment:</label>
            {config.labels.envLabel && (
              <label className="logged-in-as">{config.labels.envLabel}</label>
            )}
          </div>
          <div className="logged-in-as-div">
            <label className="logged-in-as">Logged in as:</label>
            {localStorage.getItem("dataHive_username") && (
              <label className="logged-in-as" htmlFor="client-name">
                {localStorage.getItem("dataHive_username")}
              </label>
            )}
          </div>
          <div className="client-selector-div">
            {/* add client selector dropdown */}
            <label className="client-selector-label">Company:</label>
            <select
              value={selectedClientId}
              onChange={handleClientChange}
              className="client-selector"
            >
              {clientNames.map((name, index) => (
                <option key={clientIds[index]} value={clientIds[index]}>
                  {name}
                </option>
              ))}
            </select>
          </div>
          <button onClick={handleLogout} className="submit-button">
            Logout
          </button>
        </div>
      </header>
      <div className="dashboard">
        <div>
          <Toaster position="top-center" reverseOrder={false} />
        </div>
        <div className="main-content">
          <div className={`sidebar ${sidebarOpen ? "open" : ""}`}>
            <button className="close-btn" onClick={toggleSidebar}>
              ×
            </button>
            <nav>
              <ul>
                <li className="inactive-link">
                  <Link to="/dashboard" onClick={toggleSidebar}>
                    <img
                      src="https://sanexuspreprod.blob.core.windows.net/datahive-images/datahive.png"
                      className="sidebar-logo"
                      alt=""
                    />
                    <span className="sidebar-text">Home</span>
                  </Link>
                </li>
                {products.map((product, index) => (
                  <li
                    key={index}
                    className={
                      location.pathname === product.route
                        ? "active-link"
                        : "inactive-link"
                    }
                    style={{
                      display:
                        !product.enabled || !product.access ? "none" : "auto ",
                    }}
                  >
                    <Link
                      to={
                        product.route.includes("/swagger")
                          ? `/publicAPI?route=${encodeURIComponent(
                              product.route
                            )}`
                          : product.route
                      }
                      onClick={toggleSidebar}
                      className={`sidebar-link ${
                        location.pathname === product.route ? "disabled" : ""
                      }`}
                      style={{
                        pointerEvents:
                          location.pathname === product.route ||
                          !product.enabled ||
                          !product.access
                            ? "none"
                            : "auto",
                      }}
                    >
                      <img
                        src={product.image}
                        className="sidebar-logo"
                        alt=""
                      />
                      <span className="sidebar-text">{product.name}</span>
                    </Link>
                  </li>
                ))}
              </ul>
            </nav>
          </div>
          <button className="toggle-stats" onClick={toggleSidebar}>
            {sidebarOpen ? "←" : "→"}
          </button>
          <div className="execution-section">
            <div
              className="section-header"
              onClick={() => setIsExpanded(!isExpanded)}
              style={{ cursor: "pointer" }}
            >
              <div className="caret-icon">
                {isExpanded ? <FaCaretDown /> : <FaCaretRight />}
              </div>
              <h1>Workflows</h1>
            </div>
            {/* render table only if data is available */}
            {isExpanded &&
              (data && data.data && data.data.length > 0 ? (
                <table className="table">
                  <thead>
                    <tr>
                      {/* <th>UID</th> */}
                      <th>Environment</th>
                      <th>Name</th>
                      <th>Triggers This Flow</th>
                      <th>Executes Other Flows</th>
                      <th>Status</th>
                      <th colSpan={2}>Actions</th>
                      {/* <th>Enable/Disable</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {data.data
                      .sort((a, b) => a.name.localeCompare(b.name))
                      .map((item, index) => {
                        const triggerNodes = item.nodes
                          .filter(
                            (node) =>
                              node.type.includes("Trigger") ||
                              node.type.includes("webhook")
                          )
                          .map(
                            (node) =>
                              node.name.replace(/[0-9]/g, "") +
                              (node.parameters.rule
                                ? "\n - Time: " +
                                  node.parameters.rule.interval[0]
                                    .triggerAtHour +
                                  ":" +
                                  node.parameters.rule.interval[0]
                                    .triggerAtMinute
                                : "") +
                              (node.parameters.path
                                ? "\n - Webhook Tag: " + node.parameters.path
                                : "") +
                              (node.parameters.responseMode
                                ? "\n - Webhook Response: " +
                                  (node.parameters.responseMode ===
                                  "responseNode"
                                    ? "With Webhook Response Node (may take some time to trigger)"
                                    : node.parameters.responseMode)
                                : "") +
                              "\n - Node Status: " +
                              (node.disabled ? "Disabled" : "Enabled")
                          );

                        const triggerList = triggerNodes
                          .sort((a, b) => b.localeCompare(a))
                          .join("\n");

                        const callNodes = item.nodes
                          .filter(
                            (node) =>
                              node.type.includes("executeWorkflow") &&
                              !node.type.includes("Trigger")
                          )
                          .map(
                            (node) =>
                              node.name +
                              ": \n - Node Status: " +
                              (node.disabled ? "Disabled" : "Enabled") +
                              ("\n - Workflow Name: " +
                                node.parameters.workflowId.cachedResultName.substring(
                                  node.parameters.workflowId.cachedResultName.indexOf(
                                    ": "
                                  ) + 2
                                )) +
                              ("\n - Workflow Environment: " +
                                node.parameters.workflowId.cachedResultName.substring(
                                  2,
                                  node.parameters.workflowId.cachedResultName.indexOf(
                                    ": "
                                  )
                                ))
                          );

                        const callList = callNodes.join("\n");

                        const toggleTriggers = (index) => {
                          setTriggersExpanded((prevState) => ({
                            ...prevState,
                            [index]: !prevState[index],
                          }));
                        };

                        const toggleExecutions = (index) => {
                          setExecutionsExpanded((prevState) => ({
                            ...prevState,
                            [index]: !prevState[index],
                          }));
                        };

                        return (
                          <tr key={index}>
                            {/* <td>
                          {item.id.slice(0, 10)}...
                          <button
                            onClick={() => copyIUIDToClipboard(item.id)}
                            className="icon-button-centre"
                          >
                            <FaCopy />
                          </button>
                        </td> */}
                            <td>
                              {item.name.substring(2, item.name.indexOf(": "))}
                            </td>
                            <td>
                              {item.name.substring(item.name.indexOf(": ") + 2)}
                            </td>
                            <td
                              onClick={() => toggleTriggers(index)}
                              style={{ cursor: "pointer" }}
                            >
                              {isTriggersExpanded[index] ? (
                                <>
                                  <FaCaretDown /> <pre>{triggerList}</pre>
                                </>
                              ) : (
                                <>
                                  <FaCaretRight /> {triggerNodes.length}{" "}
                                  Triggers
                                </>
                              )}
                            </td>
                            <td
                              onClick={() => toggleExecutions(index)}
                              style={{ cursor: "pointer" }}
                            >
                              {isExecutionsExpanded[index] ? (
                                <>
                                  <FaCaretDown /> <pre>{callList}</pre>
                                </>
                              ) : (
                                <>
                                  <FaCaretRight /> {callNodes.length} Execution
                                  Step
                                </>
                              )}
                            </td>
                            <td>{item.active ? "Enabled" : "Disabled"}</td>
                            <td className="button-cell">
                              <button
                                onClick={() => handleIntegrationRun(item.id)}
                                className="submit-button"
                                disabled={!triggerList.includes("Webhook")}
                                title={
                                  !triggerList.includes("Webhook")
                                    ? "A dashboard trigger cannot be done without a Webhook Trigger"
                                    : ""
                                }
                              >
                                Trigger
                              </button>
                            </td>
                            <td className="button-cell">
                              <button
                                onClick={() =>
                                  handleIntegrationDisable(item.id, item.active)
                                }
                                className="submit-button"
                              >
                                {!item.active ? "Enable" : "Disable"}
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              ) : (
                <p>No data available</p>
              ))}
          </div>
          {/* <div className={`modal ${modalIsOpen ? "open" : ""}`}>
            <button className="close-btn" onClick={closeModal}>
              ×
            </button>
            <div className="section-header">
              <h2>Node Details</h2>
            </div>
            <table className="table">
              <thead>
                <tr>
                  <th>Node ID</th>
                  <th>Node Name</th>
                </tr>
              </thead>
              <tbody>
                {modalNodes.map((node, index) => (
                  <tr key={index}>
                    <td>{node.id}</td>
                    <td>{node.name}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <button onClick={closeModal} className="submit-button">
              Close
            </button>
          </div> */}
          <div className="execution-section">
            <div className="section-header">
              <div
                className="prev-header"
                onClick={() => setExecIsExpanded(!isExecExpanded)}
                style={{ cursor: "pointer" }}
              >
                <div className="caret-icon">
                  {isExecExpanded ? <FaCaretDown /> : <FaCaretRight />}
                </div>
                <h1>Executions</h1>
              </div>
              <div className="top-select-container">
                <div className="select-text">
                  <label htmlFor="top">Execution count: </label>
                </div>
                <Select
                  className="top-select"
                  instanceId="top"
                  defaultValue="1"
                  onChange={(e) => setTopData(e.value)}
                  options={topOptions}
                  placeholder="1"
                />
              </div>
            </div>
            {/* render table only if data is available */}
            {isExecExpanded &&
              (executionData && executionData.length > 0 ? (
                <table className="table last-table">
                  <thead>
                    <tr>
                      <th>Execution ID</th>
                      <th>Workflow</th>
                      <th>Mode</th>
                      <th>Completed</th>
                      <th>Started At</th>
                      <th>Completed At</th>
                      <th>Execution Data Objects</th>
                      <th>Exception Data Objects</th>
                    </tr>
                  </thead>
                  <tbody>
                    {executionData
                      .sort((a, b) => b.id.localeCompare(a.id))
                      .map((item, index) => (
                        <tr key={index}>
                          <td>{item.id}</td>
                          <td>
                            {data.data
                              .find((node) => node.id === item.workflowId)
                              ?.name.substring(
                                data.data
                                  .find((node) => node.id === item.workflowId)
                                  ?.name.indexOf(": ") + 2
                              ) || "Unknown"}
                          </td>
                          <td>{toPascalCase(item.mode === 'cli' ? 'frontend' : item.mode)}</td>
                          <td className="button-cell">
                            {item.finished ? (
                              // If finished, display Success
                              <span title="Success">✅</span>
                            ) : (
                              // If not finished, find the error message for this item and display it in a tooltip
                              <>
                                <span title={
                                      // Find the corresponding error message for this executionId
                                      errorMessageData.find(
                                        (error) => error.executionId === item.id
                                      )?.message || "No error message available"
                                    }>
                                  ❌
                                </span>
                              </>
                            )}
                          </td>
                          <td>{new Date(item.startedAt).toLocaleString()}</td>
                          <td>{new Date(item.stoppedAt).toLocaleString()}</td>
                          <td className="button-cell">
                            {item.executionData.length}
                            <button
                              onClick={() =>
                                handleDataDownload(item.executionData, item.id)
                              }
                              className="icon-button"
                            >
                              {item.executionData.length > 0 ? (
                                <FaDownload />
                              ) : (
                                ""
                              )}
                            </button>
                          </td>
                          <td className="button-cell">
                            {item.executionErrors.length}
                            <button
                              onClick={() =>
                                handleErrorDownload(
                                  item.executionErrors,
                                  item.id
                                )
                              }
                              className="icon-button"
                            >
                              {item.executionErrors.length > 0 ? (
                                <FaDownload />
                              ) : (
                                ""
                              )}
                            </button>
                            <button
                              onClick={() =>
                                handleErrorDownloadExcel(
                                  item.executionErrors,
                                  item.id
                                )
                              }
                              className="icon-button"
                            >
                              {item.executionErrors.length > 0 ? (
                                <FaFileExcel />
                              ) : (
                                ""
                              )}
                            </button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              ) : (
                <p>No data available</p>
              ))}
          </div>

          {loading && (
            <div
              className="loading-spinner-container"
              style={{
                position: "fixed",
                top: 0,
                left: 0,
                width: "100vw",
                height: "100vh",
                backgroundColor: "rgba(0, 0, 0, 0.4)",
                backdropFilter: "blur(3px)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                zIndex: 999,
              }}
            >
              <CircleLoader
                color="#f06d37"
                loading={loading}
                cssOverride={override}
                size={150}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            </div>
          )}
        </div>
        <div className="refresh">
          <div className="auto-refresh">
            <label htmlFor="auto-refresh">Enable Auto Refresh:</label>
            <input
              type="checkbox"
              id="auto-refresh"
              checked={autoRefresh}
              onChange={() => setAutoRefresh(!autoRefresh)}
            />
          </div>
          {lastRefresh && (
            <label className="auto-refresh" htmlFor="last-refresh">
              Last Refresh: {lastRefresh}
            </label>
          )}
        </div>
      </div>
    </div>
  );
}
export default NexusDashboard;
